<template>
  <div id="login-form">
    <div class="title">
      <span>登录</span>
    </div>
    <el-form
      ref="loginForm"
      label-position="right"
      :model="loginForm"
      :rules="rules"
    >
      <el-form-item prop="userName">
        <el-autocomplete  :fetch-suggestions="querySearch" v-model="loginForm.userName" placeholder="用户名" clearable @select="handleSelect" @change="loginForm.password=null">
          <template #prefix>
            <el-icon style="width: 100%; height: 100%"><user /></el-icon>
          </template>
          <template #default="{ item }">
            <div class="value">{{ item.userName }}</div>
          </template>
        </el-autocomplete>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="loginForm.password"
          show-password
          placeholder="密码"
          clearable
        >
          <template #prefix>
            <el-icon style="width: 100%; height: 100%"><key /></el-icon>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-checkbox ref="isRememberPassword" size="small" v-model="this.isRememberPassword" :checked="this.isRememberPassword"
          >记住密码</el-checkbox
        >
      </el-form-item>
      <el-form-item>
        <el-button type="primary" id="button" :disable="this.btdisable" @click="submit()"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script >
import { User, Key } from "@element-plus/icons-vue";
import { decode } from "utils/token.js";
import { login } from "network/login.js";
export default {
  components: {
    User,
    Key,
  },
  props:{
    Id:{
      type:String,
      default:""
    },
    password:{
      type:String,
      default:""
    }
  },
  data() {
    return {
      loginForm: {
        userName: "",
        password: "",
      },
      rules: {
        userName: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 5,
            message: "请输入正确格式密码",
            trigger: "blur",
          },
        ],
      },
      users:[],
      isRememberPassword: false,
      btdisable: false,
    };
  },

  created() {
    this.prepare();
  },
  mounted() {
    //清除elm输入框的颜色
  this.$nextTick(() => {
    const inputElement = this.$el.querySelector('.el-input__inner');
    if (inputElement) {
      inputElement.style.backgroundColor = 'transparent';
      inputElement.style.borderColor = 'transparent';
      inputElement.style.boxShadow = 'none';
    }
  });
},
  methods: {
    handleSelect(item){
          this.loginForm.userName=item.userName
          if(item.password!==undefined&&item.password!=="")
          {this.loginForm.password=item.password;this.isRememberPassword=true;}
          
      },
    querySearch(queryString  ,callback){
      if(queryString==undefined||queryString===""){
            callback(this.users)
            return
          }
        let results=this.users.filter((element)=>{
            return (element.userName.toLowerCase().indexOf(queryString.toLowerCase())===0)
        })
        callback(results)
    },
    prepare() {
      if(this.Id!==""&&this.password!=="")
      {
        this.loginForm.userName=this.Id
        this.loginForm.password=this.password
      }
      if (localStorage.getItem("user") == null) 
        return;
      let users = JSON.parse(localStorage.getItem("user"));
      this.users = users;
    },
    rememberUser(name, password) {
      if(!(this.isRememberPassword))
        return
      if (name == undefined) return;
      let user = { userName: name, password: password };
      let userList = [];
      if (localStorage.getItem("user") == null) {
          userList.push(user);
          localStorage.setItem("user", JSON.stringify(userList));
        } else {
          let userList = JSON.parse(localStorage.getItem("user"));
          let flag = true;
          for (let item of userList) {
            if (item.userName === user.userName) {
              item = user;
              flag = false;
              break;
            }
          }
          if (flag) userList.push(user);

          localStorage.setItem("user", JSON.stringify(userList));
        }
      
    },
    submit() {
      this.btdisable = true;
      if ( localStorage.getItem("currentLogin")!== null) {
        this.$router.push({ name: "首页" });
        return;
      }
      let formInstance = this.$refs.loginForm;
      let userName=this.loginForm.userName,password=this.loginForm.password
      if (formInstance) {
        formInstance.validate((valid) => {
          if (valid) {
            setTimeout(() => {
              login(userName, password).then(
                (res) => {
                  this.success(res.data);
                  this.rememberUser(userName,password)
                  this.btdisable = false;
                },
                (err) => {
                  this.$message.warning("登陆失败,联系管理员");
                  console.log("登录错误:" + err);
                  this.btdisable = false;
                }
              );
            }, 2000);
          } else {
            this.$message.warning("格式有误");
          }
        });
      }
    },
    success(data) {
      let user = decode(data);
      localStorage.setItem('currentLogin',data)
      this.$store.commit("SET_USER", user);

      this.$store.dispatch("getUserInfomation").then(res=>{
        this.$store.dispatch("areas/initArea");
         this.$store.dispatch("companies/initCompanies")
      })
      this.$router.push({ name: "首页" });
      this.$message.success(user.name+"登陆成功");
    },
  },
};
</script> 

<style lang="less" scoped>
#login-form {
  width: 334px !important;
  height: 294px !important;
  border-radius: 8px;
  box-shadow: var(--el-box-shadow-base);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: white;
   background-color: rgba(0, 0, 0, 0.5);
   .title {
    font-size: 20px;
    font-weight: bold;
    color: white;
   }
  .sign {
    text-align: center;
    font-size: 14px;
    color: white;
    // text-decoration: underline;
    flex: 0 0;
    a {
      color: #3498db;
    }
  }
  .el-form {
    width: 60%;
    flex: 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .el-checkbox {
      font-size: 16;
      color: #3498db;
    }
  }
  #button {
     width: 100px;
    height: 30px;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 25px;
    cursor: pointer;
    border: 1px solid #ccc;
  }
  /deep/ .el-input__inner {
  border-color: transparent; /* 去掉边框颜色 */
  background-color: transparent; /* 去掉背景颜色 */
  box-shadow: none; /* 去掉阴影 */
}
 
}
</style>